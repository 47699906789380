<template>
  <div id="app">
    <helathSuggest />
    <checkResult />
  </div>
</template>

<script>
import checkResult from "./components/checkResult.vue";
import helathSuggest from "./components/helathSuggest.vue";

export default {
  name: "App",
  components: {
    helathSuggest,
    checkResult,
  },
};
</script>

<style>
body {
  display: block;
  margin: 0px;
}
#app {
  width: 375px;
  margin: auto;
}
</style>
