<template>
  <div style="padding: 20px 15px">
    <div class="result" v-if="Object.keys(data).length > 0" id="mycanvas">
      <div class="title">请查收您的专属报告</div>
      <div class="line"></div>
      <div class="content">
        <div class="text_content">
          <div class="">
            <div class="first">
              <div class="left">
                <img :src="tou" alt="" style="width: 44px; height: 44px" />
              </div>
              <div class="right">
                <div class="right_first_line">
                  <!-- 名称 -->
                  <div class="name">
                    {{ data.name }}
                  </div>
                  <!-- 教育 -->
                  <div class="education">教育年限：{{ data.eduTotal }}年</div>
                </div>
                <!--  -->
                <div class="right_two_line">
                  <div class="education">{{ data.gender }} {{ data.age }}</div>
                  <div class="education">
                    {{ data.reportDate }}
                  </div>
                </div>
              </div>
            </div>

            <div class="test_number">
              <div class="education">编号：{{ data.reportNo }}</div>
              <div class="education">主试人：{{ data.doctorName }}</div>
            </div>
          </div>
          <div class="dashed"></div>
          <!-- <u-line dashed></u-line> -->
          <div class="header_title">
            <img src="./img/first.png" alt="" style="" />
            <span style="" class="text">总体评价</span>
          </div>
          <div
            class="status"
            style="color: #00cc6a"
            v-if="data.assessResult == 1"
          >
            认知正常
          </div>
          <div class="status" v-else>认知损伤</div>
          <div class="zhushi" style="margin-bottom: 15px; margin-top: 8px">
            结果解释：您本次测评结果显示为认知损伤，说明您存在患有认知障碍的风险。
          </div>
          <!-- <u-line dashed></u-line> -->
          <div class="dashed"></div>
          <div class="header_title">
            <img src="./img/secend.png" alt="" style="" />
            <span style="" class="text">脑健康指数</span>
          </div>
          <div
            class="source"
            style="margin: 0 auto; width: 100%; text-align: center"
          >
            <span class="source_number">{{ data.score }}</span>
            <span class="fen">分</span>
          </div>
          <!-- 进度条外层 -->
          <div class="progress">
            <!-- <u-line-progress
              :percentage="data.score"
              :showText="false"
              inactiveColor="#cff5e1"
            >
            </u-line-progress> -->
            <div class="shuzi">
              <div class="">0</div>
              <div class="">10</div>
              <div class="">20</div>
              <div class="">30</div>
              <div class="">40</div>
              <div class="">50</div>
              <div class="">60</div>
              <div class="">70</div>
              <div class="">80</div>
              <div class="">90</div>
              <div class="">100</div>
            </div>
          </div>
          <div class="zhushi" style="padding-bottom: 2px; padding-top: 4px">
            脑健康指数越高，表明老年人的脑健康状况越好
          </div>
          <!-- <u-line dashed></u-line> -->
          <div class="dashed" style="padding-top: 15px"></div>
          <div class="header_title">
            <img src="./img/san.png" alt="" style="" />
            <span style="" class="text">测评得分</span>
          </div>
          <div
            class="box_1"
            v-for="(item, index) in data.detailContents"
            :key="index"
          >
            <div class="algin">
              <div class="heiti" style="">
                {{ item.content }} {{ item.projectName }}
              </div>
              <div
                class="text"
                style="color: #00cc6a"
                v-if="item.result == '正常'"
              >
                正常
              </div>
              <div class="text" style="color: #d96c36" v-else>异常</div>
            </div>
            <div class="algin">
              <div class="zhushi">得分：{{ item.score }}</div>
              <div class="zhushi">参考值：{{ item.reference }}</div>
            </div>
            <div class="zhushi">得分解释：{{ item.scoreExplanation }}</div>
            <div class="" style="margin: 15px 0">
              <div :class="index == 2 ? 'dashed' : 'solid'"></div>
              <!-- <u-line :dashed="index == 2"></u-line> -->
            </div>
          </div>

          <div class="header_title">
            <img src="./img/four.png" alt="" style="" />
            <span style="" class="text">脑健康测评数说明</span>
          </div>
          <div class="zhushi">
            脑健康测评针对总体认知能力、主诉认知状况、记忆力、思维能力、执行功能、反应速度等领域进行评估，从而了解健康状态。
          </div>
          <div class="box_2">
            <span class="zhushi" style="color: #0ace70">主诉认知水平：</span>
            <span class="zhushi">
              是指人们主观反映自己的记忆力、注意力、思维能力、判断能力等认知功能的状况。
            </span>
          </div>
          <div class="box_2">
            <span class="zhushi" style="color: #0ace70">总体认知能力：</span>
            <span class="zhushi">
              是最基本的、经常用到的认知能力，是对记忆力、注意力、定向力、计算能力等各种能力的统称。
            </span>
          </div>
          <div class="box_2">
            <span class="zhushi" style="color: #0ace70">情景记忆：</span>
            <span class="zhushi">
              是对个人亲身经历的、发生在一定时间和地点的事件（情景）的记忆，情景记忆衰退是脑老化和痴呆相关疾病较敏感的指标。
            </span>
          </div>
          <div class="box_2">
            <span class="zhushi" style="color: #0ace70">执行功能：</span>
            <span class="zhushi">
              是指人脑在进行复杂的认知任务时，协调处理各个心理过程，保证个体以灵活、优化的方式实现特定目的。
            </span>
          </div>
          <div class="" style="margin-top: 40rpx">
            <!-- <u-line dashed></u-line> -->
            <div class="dashed" style="padding-top: 20px"></div>
          </div>

          <div class="header_title">
            <img src="./img/five.png" alt="" style="" />
            <span style="" class="text">脑健康指导建议</span>
          </div>
          <div class="zhushi">
            <div
              v-html="
                data.adviceContents && data.adviceContents[0]
                  ? data.adviceContents[0].content
                  : ''
              "
            ></div>
          </div>
          <div
            class="zhushi_hui"
            style="
              margin-top: 20px;
              color: #a1b0b3;
              font-size: 20rpx;
              height: 33px;
              line-height: 18px;
            "
          >
            <div v-html="data.noticeText"></div>
          </div>

          <div class="" style="margin-top: 20px">
            <!-- <u-line dashed></u-line> -->
            <div class="dashed"></div>
          </div>

          <div class="header_title">
            <img src="./img/five.png" alt="" style="" />
            <span style="" class="text">领取电子报告</span>
          </div>
          <div class="bottom">
            <div class="zhushi text">
              <div class="">
                关注公众号获取
                <br />
                详细的筛查报告及专业的健康建议
              </div>
            </div>
            <div class="imgage">
              <img
                src="./img/ainao.jpg"
                alt=""
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <img :src="imgUrl" alt="" style="width: 100%; height: 100%" />
  </div>
</template>
<script>
import res from "./res.json";
import html2canvas from "html2canvas";
export default {
  data() {
    return {
      data: {},
      imgUrl: "",
    };
  },
  computed: {
    tou() {
      return "https://www.ainaoxingdong.com/api/an-media/images/an/static/personal/morenAv.png";
    },
  },
  methods: {
    handleGenerate() {
      const dom = document.querySelector("#mycanvas");
      html2canvas(dom, {}).then((canvas) => {
        this.imgUrl = canvas.toDataURL();
        // document.querySelector("#append").appendChild(canvas);
      });
    },
  },
  mounted() {
    console.log(res, "resresresresresresres");

    const data = res.data;
    this.data = data.data;
    this.$nextTick(() => {
      this.handleGenerate();
    });
  },
};
</script>

<style lang="scss" scoped>
// 虚线
.dashed {
  widows: 0;
  height: 0;
  border-bottom: 1px dashed #c8e7e6;
}
// 实线
.solid {
  widows: 0;
  height: 0;
  border-bottom: 1px solid #c8e7e6;
}
.fixed_bottom_box {
  width: 100vw;
  height: 132px;
  background: #ffffff !important;
  // box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.05);
  // position: fixed;
  // bottom: 0;

  .fixed_bottom_box_title {
    height: 24px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 550;
    color: #003941;
    line-height: 24px;
    padding-top: 20px;
    text-align: center;
  }

  .btns {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }

  .btns_left {
    width: 150px;
    height: 48px;
    background: #66ffb5;
    box-shadow: 4px 6px 18px 0px rgba(31, 90, 102, 0.1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #003941;
  }

  .btns_right {
    width: 150px;
    height: 48px;
    background: #004751;
    box-shadow: 4px 6px 18px 0px rgba(31, 90, 102, 0.1);
    border-radius: 10px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #66ffb5;
    line-height: 48px;
    text-align: center;
  }
}

.zhushi_hui {
  width: 305px;
  height: 33px;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a1b0b3;
  line-height: 14px;
}

.header_title {
  margin: 15px 0;

  img {
    width: 16px;
    height: 16px;
    position: relative;
    top: 2px;
  }

  .text {
    height: 24px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 550;
    color: #003941;
    line-height: 24px;
    margin-left: 5px;
  }
}

.status {
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 550;
  color: #d96c36;
  line-height: 24px;
}

.zhushi {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #336c74;
  line-height: 17px;
}

.source {
  .source_number {
    height: 24px;
    font-size: 20px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    color: #003941;
    line-height: 24px;
  }

  .fen {
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #336c74;
    line-height: 17px;
  }
}

.progress {
  margin-top: 5px;
  width: 100%;
  height: 12px;

  // border-radius: 14rpx;
  // background: linear-gradient(270deg, #00CC6A 0%, #F2A247 100%);
  // opacity: 0.2;
  .u-line-progress__line {
    background: linear-gradient(270deg, #00cc6a 0%, #f2a247 100%);
  }

  .u-line-progress__background {
    background-color: #cff5e1;
  }

  .shuzi {
    display: flex;
    justify-content: space-around;
    padding-top: 4px;

    view {
      text-align: center;
      padding: 0 12px;
      flex: 1;
      width: 11px;
      height: 11px;
      font-size: 8px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #b8c0cc;
      line-height: 11px;
    }
  }
}

.box_1 {
  .algin {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
    width: 100%;
  }

  .heiti {
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 550;
    color: #004751;
    line-height: 17px;
  }

  .text {
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;

    line-height: 17px;
  }
}

.box_2 {
  margin-top: 15rpx;

  .box_title {
  }
}

.bottom {
  display: flex;
  align-items: center;

  .text {
    flex: 1;
  }

  .imgage {
    width: 84px;
    height: 84px;
    background-color: hotpink;
  }
}

.result {
  background: linear-gradient(180deg, #00cc6a 0%, #d8feeb 100%);
  width: 100%;
  position: relative;

  .title {
    width: 180px;
    height: 30px;
    font-size: 20px;
    font-family: FZLTDHJW--GB1-0, FZLTDHJW--GB1;
    font-weight: normal;
    color: #ffffff;
    line-height: 30px;
    padding-left: 35px;
    padding-top: 20px;
  }

  .line {
    width: 345px;
    height: 10px;
    background: #336c74;
    border-radius: 10px;
    margin: auto;
    margin-top: 10px;
    z-index: 1;
    position: relative;
    opacity: 0.5;
    bottom: -5px;
  }

  .content {
    width: 335px;
    background: #ffffff;
    box-shadow: 0px 6px 16px 0px rgba(75, 126, 139, 0.1);
    margin: auto;
    z-index: 44;
    position: relative;
    opacity: 1;
    border-radius: 5px 5px 0rpx 0rpx;

    .text_content {
      padding: 20px 15px;

      .education {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #336c74;
        line-height: 17px;
      }

      .first {
        display: flex;

        .left {
          width: 44px;
          height: 44px;
        }

        .right {
          flex: 1;
          padding-left: 15px;

          .right_first_line {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .name {
              height: 24px;
              font-size: 17px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 550;
              color: #003941;
              line-height: 24px;
            }

            .education {
              line-height: 24px;
            }
          }

          .right_two_line {
            display: flex;
            width: 100%;
            justify-content: space-between;
          }
        }
      }

      .test_number {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 15px;
      }
    }
  }
}
</style>